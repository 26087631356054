import foto1 from "./Rodrigo Melo - VISTA IMPERIAL - 29.09.2020_RMF_026_WEB.jpg";
import foto2 from "./Rodrigo Melo - VISTA IMPERIAL - 29.09.2020_RMF_037_WEB.jpg";
import foto3 from "./Rodrigo Melo - VISTA IMPERIAL - 29.09.2020_RMF_049_WEB.jpg";
import foto4 from "./Rodrigo Melo - VISTA IMPERIAL - 29.09.2020_RMF_073_WEB.jpg";
import foto5 from "./Rodrigo Melo - VISTA IMPERIAL - 29.09.2020_RMF_076_WEB.jpg";
import foto6 from "./Rodrigo Melo - VISTA IMPERIAL - 29.09.2020_RMF_094_WEB.jpg";
import foto7 from "./Rodrigo Melo - VISTA IMPERIAL - 29.09.2020_RMF_100_WEB.jpg";
import foto8 from "./Rodrigo Melo - VISTA IMPERIAL - 29.09.2020_RMF_100_WEB.jpg";
import foto9 from "./Rodrigo Melo - VISTA IMPERIAL - 29.09.2020_RMF_136_WEB.jpg";

export const fotosResidencialRonni = [
  foto1,
  foto2,
  foto3,
  foto4,
  foto5,
  foto6,
  foto7,
  foto8,
  foto9,
];
