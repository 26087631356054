import foto1 from "./Rodrigo Melo - SOLAR MANGUEIRAS - 31.08.2022_RMF_004_WEB (1).jpg";
import foto2 from "./Rodrigo Melo - SOLAR MANGUEIRAS - 31.08.2022_RMF_046_WEB (1).jpg";
import foto3 from "./Rodrigo Melo - SOLAR MANGUEIRAS - 31.08.2022_RMF_055_WEB (1).jpg";
import foto4 from "./Rodrigo Melo - SOLAR MANGUEIRAS - 31.08.2022_RMF_184_WEB (1).jpg";
import foto5 from "./Rodrigo Melo - SOLAR MANGUEIRAS - 31.08.2022_RMF_386_WEB (1).jpg";
import foto6 from "./Rodrigo Melo - SOLAR MANGUEIRAS - 31.08.2022_RMF_407_WEB (1).jpg";
import foto7 from "./Rodrigo Melo - SOLAR MANGUEIRAS - 31.08.2022_RMF_414_WEB (1).jpg";
import foto8 from "./Rodrigo Melo - SOLAR MANGUEIRAS - 31.08.2022_RMF_420_WEB (1).jpg";
import foto9 from "./Rodrigo Melo - SOLAR MANGUEIRAS - 31.08.2022_RMF_423_WEB (1).jpg";

export const fotosResidencialSolar = [
  foto1,
  foto2,
  foto3,
  foto4,
  foto5,
  foto6,
  foto7,
  foto8,
  foto9,
];
