import foto1 from "./Rodrigo Melo - GOURMET - 18.03.2020_RMF_001_WEB.jpg";
import foto2 from "./Rodrigo Melo - GOURMET - 18.03.2020_RMF_013_WEB.jpg";
import foto3 from "./Rodrigo Melo - GOURMET - 18.03.2020_RMF_021_WEB.jpg";
import foto4 from "./Rodrigo Melo - GOURMET - 18.03.2020_RMF_028_WEB.jpg";
import foto5 from "./Rodrigo Melo - GOURMET - 18.03.2020_RMF_034_WEB.jpg";
import foto6 from "./Rodrigo Melo - GOURMET - 18.03.2020_RMF_039_WEB.jpg";
import foto7 from "./Rodrigo Melo - GOURMET - 18.03.2020_RMF_043_WEB.jpg";
import foto8 from "./Rodrigo Melo - GOURMET - 18.03.2020_RMF_082_WEB.jpg";
import foto9 from "./Rodrigo Melo - GOURMET - 18.03.2020_RMF_091_WEB.jpg";
import foto10 from "./Rodrigo Melo - GOURMET - 18.03.2020_RMF_095_WEB.jpg";
import foto11 from "./Rodrigo Melo - GOURMET - 18.03.2020_RMF_112_WEB.jpg";
import foto12 from "./Rodrigo Melo - GOURMET - 18.03.2020_RMF_123_WEB.jpg";
import foto13 from "./Rodrigo Melo - GOURMET - 18.03.2020_RMF_139_WEB.jpg";
import foto14 from "./Rodrigo Melo - GOURMET - 18.03.2020_RMF_145_WEB.jpg";
import foto15 from "./Rodrigo Melo - GOURMET - 18.03.2020_RMF_154_WEB.jpg";

export const fotosResidencialLeoeCamila = [
  foto1,
  foto2,
  foto3,
  foto4,
  foto5,
  foto6,
  foto7,
  foto8,
  foto9,
  foto10,
  foto11,
  foto12,
  foto13,
  foto14,
  foto15,
];
