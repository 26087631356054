import foto1 from "./Rodrigo Melo - ED. SPLENDOR - 02.02.2022_RMF_034_WEB (1).jpg";
import foto2 from "./Rodrigo Melo - ED. SPLENDOR - 02.02.2022_RMF_040_WEB (1).jpg";
import foto3 from "./Rodrigo Melo - ED. SPLENDOR - 02.02.2022_RMF_064_WEB (1).jpg";
import foto4 from "./Rodrigo Melo - ED. SPLENDOR - 02.02.2022_RMF_088_WEB (1).jpg";
import foto5 from "./Rodrigo Melo - ED. SPLENDOR - 02.02.2022_RMF_091_WEB (1).jpg";
import foto6 from "./Rodrigo Melo - ED. SPLENDOR - 02.02.2022_RMF_100_WEB (1).jpg";
import foto7 from "./Rodrigo Melo - ED. SPLENDOR - 02.02.2022_RMF_127_WEB (1).jpg";
import foto8 from "./Rodrigo Melo - ED. SPLENDOR - 02.02.2022_RMF_148_WEB (1).jpg";
import foto9 from "./Rodrigo Melo - ED. SPLENDOR - 02.02.2022_RMF_151_WEB (1).jpg";
import foto10 from "./Rodrigo Melo - ED. SPLENDOR - 02.02.2022_RMF_151_WEB (2).jpg";

export const fotosResidencialHortoMarcio = [
  foto1,
  foto2,
  foto3,
  foto4,
  foto5,
  foto6,
  foto7,
  foto8,
  foto9,
  foto10,
];
