import foto1 from "./IMG_0272.jpg";
import foto2 from "./IMG_0275.jpg";
import foto3 from "./IMG_0269-1.jpg";
import foto4 from "./IMG_0277-1.jpg";
import foto5 from "./IMG_0279-2.jpg";
import foto6 from "./IMG_0286.jpg";
import foto7 from "./IMG_0290-1.jpg";
import foto8 from "./IMG_0291.jpg";
import foto9 from "./IMG_0292-1.jpg";
import foto10 from "./IMG_0294-1.jpg";
import foto11 from "./IMG_0298.jpg";
import foto12 from "./IMG_0299.jpg";

export const fotosResidencialEnzoFernada = [
  foto1,
  foto2,
  foto3,
  foto4,
  foto5,
  foto6,
  foto7,
  foto8,
  foto9,
  foto10,
  foto11,
  foto12,
];
